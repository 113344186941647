.form-switch .form-check-input:checked {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-primary) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-primary) / var(--tw-bg-opacity));
}
.form-check-input[type="checkbox"]:checked {
  border-color: rgb(var(--color-primary) / var(--tw-border-opacity));
  --tw-border-opacity: 0.1;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-primary) / var(--tw-bg-opacity));
}
.form-switch .form-check-input {
  width: 38px;
  height: 24px;
  padding: 1px;
  position: relative;
  border-radius: 9999px;
  background-image: none;
}
.form-check-input[type="checkbox"] {
  cursor: pointer;
  border-radius: 0.25rem;
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-slate-200) / var(--tw-border-opacity));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
[type='checkbox']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}
[type='checkbox']:checked, [type='radio']:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.form-check-input {
  transition-property: all;
  transition-duration: 100ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
[type='checkbox'] {
  border-radius: 0px;
}
[type='checkbox'], [type='radio'] {
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;
}
button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}
*, ::before, ::after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
*, ::before, ::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}
.box {
  box-shadow: 0px 3px 20px #0000000b;
  position: relative;
  border-radius: 0.375rem;
  border-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.content {
  border-radius: 30px;
  padding: 0px 22px;
  min-height: 100vh;
  min-width: 0px;
  flex: 1 1;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-slate-100) / var(--tw-bg-opacity));
  padding-bottom: 2.5rem;
}
html body {
  overflow-x: hidden;
  /* padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 2rem;
  padding-right: 2rem; */
  font-family: Roboto;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(var(--color-slate-800) / var(--tw-text-opacity));
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  margin: 0;
  line-height: inherit;
}
:root {
  --color-primary: 30 64 175;
  --color-secondary: 226 232 240;
  --color-success: 132 204 22;
  --color-info: 6 182 212;
  --color-warning: 250 204 21;
  --color-pending: 249 115 22;
  --color-danger: 220 38 38;
  --color-light: 241 245 249;
  --color-dark: 30 41 59;
  --color-slate-50: 248 250 252;
  --color-slate-100: 241 245 249;
  --color-slate-200: 226 232 240;
  --color-slate-300: 203 213 225;
  --color-slate-400: 148 163 184;
  --color-slate-500: 100 116 139;
  --color-slate-600: 71 85 105;
  --color-slate-700: 51 65 85;
  --color-slate-800: 30 41 59;
  --color-slate-900: 15 23 42;
}
html {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-primary) / var(--tw-bg-opacity));
}
html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.form-switch .form-check-input:checked::before {
  margin-left: 14px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.form-switch .form-check-input:before {
}
.form-switch .form-check-input:before {
  content: "";
  width: 20px;
  height: 20px;
  box-shadow: 1px 1px 3px rgb(0 0 0 / 25%);
  transition-property: margin-left;
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 9999px;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
*, ::before, ::after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
::before, ::after {
  --tw-content: '';
}
*, ::before, ::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}
*, ::before, ::after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
::before, ::after {
  --tw-content: '';
}
*, ::before, ::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}
[type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
}
[type='checkbox'],[type='radio'] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;
}
[type='checkbox']:focus,[type='radio']:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
}

.form-check {
  display: flex;
  align-items: center;
}
.form-check-label {
  margin-left: 0.5rem;
  cursor: pointer;
}
.form-check-input {
  transition-property: all;
  transition-duration: 100ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.form-check-input[type="radio"] {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-slate-200) / var(--tw-border-opacity));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.form-check-input[type="radio"]:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(var(--color-primary) / var(--tw-ring-opacity));
  --tw-ring-opacity: 0.2;
  --tw-ring-offset-width: 0px;
}
.dark .form-check-input[type="radio"] {
  border-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-darkmode-800) / var(--tw-bg-opacity));
}
.dark .form-check-input[type="radio"]:focus {
  --tw-ring-color: rgb(var(--color-slate-700) / var(--tw-ring-opacity));
  --tw-ring-opacity: 0.5;
}
.form-check-input[type="radio"]:checked {
  border-color: rgb(var(--color-primary) / var(--tw-border-opacity));
  --tw-border-opacity: 0.1;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-primary) / var(--tw-bg-opacity));
}
.form-check-input[type="radio"]:disabled:not(:checked) {
  cursor: not-allowed;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-slate-100) / var(--tw-bg-opacity));
}
.dark .form-check-input[type="radio"]:disabled:not(:checked) {
  background-color: rgb(var(--color-darkmode-800) / 0.5);
}
.form-check-input[type="radio"]:disabled:checked {
  cursor: not-allowed;
  opacity: 0.7;
}
.dark .form-check-input[type="radio"]:disabled:checked {
  background-color: rgb(var(--color-darkmode-800) / 0.5);
}
.form-check-input[type="checkbox"] {
  cursor: pointer;
  border-radius: 0.25rem;
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-slate-200) / var(--tw-border-opacity));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.form-check-input[type="checkbox"]:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(var(--color-primary) / var(--tw-ring-opacity));
  --tw-ring-opacity: 0.2;
  --tw-ring-offset-width: 0px;
}
.dark .form-check-input[type="checkbox"] {
  border-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-darkmode-800) / var(--tw-bg-opacity));
}
.dark .form-check-input[type="checkbox"]:focus {
  --tw-ring-color: rgb(var(--color-slate-700) / var(--tw-ring-opacity));
  --tw-ring-opacity: 0.5;
}
.form-check-input[type="checkbox"]:checked {
  border-color: rgb(var(--color-primary) / var(--tw-border-opacity));
  --tw-border-opacity: 0.1;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-primary) / var(--tw-bg-opacity));
}
.form-check-input[type="checkbox"]:disabled:not(:checked) {
  cursor: not-allowed;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-slate-100) / var(--tw-bg-opacity));
}
.dark .form-check-input[type="checkbox"]:disabled:not(:checked) {
  background-color: rgb(var(--color-darkmode-800) / 0.5);
}
.form-check-input[type="checkbox"]:disabled:checked {
  cursor: not-allowed;
  opacity: 0.7;
}
.dark .form-check-input[type="checkbox"]:disabled:checked {
  background-color: rgb(var(--color-darkmode-800) / 0.5);
}
.form-switch .form-check-input {
    width: 38px;
    height: 24px;
    padding: 1px;
    position: relative;
    border-radius: 9999px;
    background-image: none;
  }
.form-switch .form-check-input:before {
      content: "";
      width: 20px;
      height: 20px;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
      transition-property: margin-left;
      position: absolute;
      top: 0px;
      bottom: 0px;
      margin-top: auto;
      margin-bottom: auto;
      border-radius: 9999px;
      transition-duration: 200ms;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
.dark .form-switch .form-check-input:before {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-darkmode-600) / var(--tw-bg-opacity));
}
.form-switch .form-check-input:before {
    }
.form-switch .form-check-input:checked {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-primary) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-primary) / var(--tw-bg-opacity));
}
.form-switch .form-check-input:checked::before {
        margin-left: 14px;
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity));
      }
/* Default Form Control */
.form-control {
  width: 100%;
  border-radius: 0.375rem;
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-slate-200) / var(--tw-border-opacity));
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.form-control::-moz-placeholder {
  color: rgb(var(--color-slate-400) / 0.9);
}
.form-control:-ms-input-placeholder {
  color: rgb(var(--color-slate-400) / 0.9);
}
.form-control::placeholder {
  color: rgb(var(--color-slate-400) / 0.9);
}
.form-control:focus {
  border-color: rgb(var(--color-primary) / var(--tw-border-opacity));
  --tw-border-opacity: 0.4;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(var(--color-primary) / var(--tw-ring-opacity));
  --tw-ring-opacity: 0.2;
}
.dark .form-control {
  border-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-darkmode-800) / var(--tw-bg-opacity));
  transition-property: none;
}
.dark .form-control::-moz-placeholder {
  color: rgb(var(--color-slate-500) / 0.8);
}
.dark .form-control:-ms-input-placeholder {
  color: rgb(var(--color-slate-500) / 0.8);
}
.dark .form-control::placeholder {
  color: rgb(var(--color-slate-500) / 0.8);
}
.dark .form-control:focus {
  --tw-ring-color: rgb(var(--color-slate-700) / var(--tw-ring-opacity));
  --tw-ring-opacity: 0.5;
}
.form-control:disabled, .form-control[readonly] {
  cursor: not-allowed;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-slate-100) / var(--tw-bg-opacity));
}
.dark .form-control:disabled, .dark .form-control[readonly] {
  border-color: transparent;
  background-color: rgb(var(--color-darkmode-800) / 0.5);
}
/* Default Sizes */
.form-control-sm {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
}
.form-control-lg {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}
/* Form Control Rounded */
.form-control-rounded {
  border-radius: 9999px;
}
.form-help {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(var(--color-slate-500) / var(--tw-text-opacity));
}
.form-inline {
  display: flex;
  align-items: center;
}
.form-inline .form-label {
  margin-bottom: 0px;
  margin-right: 1.25rem;
  text-align: right;
}
.form-inline .form-control {
  flex: 1 1 0%;
}
.form-label {
  margin-bottom: 0.5rem;
  display: inline-block;
}
/* Default Form Select */
.form-select {
  /* width: 100%; */
  border-radius: 0.375rem;
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-slate-200) / var(--tw-border-opacity));
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 2rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.form-select:focus {
  border-color: rgb(var(--color-primary) / var(--tw-border-opacity));
  --tw-border-opacity: 0.4;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(var(--color-primary) / var(--tw-ring-opacity));
  --tw-ring-opacity: 0.2;
}
.dark .form-select {
  border-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-darkmode-800) / var(--tw-bg-opacity));
}
.dark .form-select:focus {
  --tw-ring-color: rgb(var(--color-slate-700) / var(--tw-ring-opacity));
  --tw-ring-opacity: 0.5;
}
.form-select:disabled, .form-select[readonly] {
  cursor: not-allowed;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-slate-100) / var(--tw-bg-opacity));
}
.dark .form-select:disabled, .dark .form-select[readonly] {
  background-color: rgb(var(--color-darkmode-800) / 0.5);
}
/* Default Sizes */
.form-select-sm {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.5rem;
  padding-right: 2rem;
  font-size: 0.75rem;
  line-height: 1rem;
}
.form-select-lg {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 1rem;
  padding-right: 2rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.input-group > .input-group-text, .input-group > .form-control {
  border-radius: 0px;
}
.input-group > .input-group-text:not(:first-child), .input-group > .form-control:not(:first-child) {
        border-left-color: transparent;
      }
.input-group > .input-group-text:first-child, .input-group > .form-control:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.input-group > .input-group-text:last-child, .input-group > .form-control:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.input-group > .form-control {
  z-index: 10;
}
@media (max-width: calc(640px - 1px)) {
  .form-inline {
      display: block;
    }
  .form-inline .form-label {
      margin-bottom: 0.5rem;
    }
  .form-inline .form-label {
      margin-right: 0px;
    }
  .form-inline .form-label {
      text-align: left;
    }
    }

.inbox .form-check-input {
--tw-border-opacity: 1;
border-color: rgb(var(--color-slate-400) / var(--tw-border-opacity));
}
.inbox .form-check-input:checked {
--tw-border-opacity: 1;
border-color: rgb(var(--color-primary) / var(--tw-border-opacity));
}
.has-error .form-control {
--tw-border-opacity: 1;
border-color: rgb(var(--color-danger) / var(--tw-border-opacity));
}
.dark .has-error .form-control {
border-color: rgb(var(--color-danger) / 0.7);
}
.wajib{
  padding-bottom: 2px;
  font-weight: bold;
  color: #ff0000;
}
.text-theme-1 {
  --tw-text-opacity: 1;
  color: rgba(211,55,36,0.9);
}
.bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-slate-100)/var(--tw-bg-opacity));
}
.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(var(--color-slate-500)/var(--tw-text-opacity));
}
.bg-primary{
  background-color: rgb(27, 63, 170);
}